import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiDialog, UiDialogType } from '../src/';
import { DialogsExample } from './utils/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uidialog"
    }}>{`UiDialog`}</h1>
    <hr></hr>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/dialog/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Renders a dialog on top of the content`}</p>
    </blockquote>
    <p>{`The dialogs currently uses a default controller that keep track of dialogs using a react state, if you prefer to use your own logic
you can follow up this `}<a parentName="p" {...{
        "href": "./custom-dialog-controller"
      }}>{`doc`}</a>{` to create you own custom dialogs controller.`}</p>
    <h2 {...{
      "id": "installation-️"
    }}>{`Installation ⚙️`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/dialog`}</p>
    </blockquote>
    <h2 {...{
      "id": "usedialog-"
    }}>{`useDialog 📡`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`useDialog`}</inlineCode>{` hook is used to instantiate a dialog and be able to communicate with the dialogs controller. The `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/dialog/src/provider/use-dialog-hook.ts#LL10C6-L10C19"
      }}>{`UseDialog response`}</a>{` will bring a few properties that we will use to manipulate our dialogs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`{
  isOpen, // boolean, will give us if a dialogs is opened or not.
  actions: {
    openDialog, // () => void, function to open a dialog
    closeDialog // () => void, function to close a dialog
  }
};
`}</code></pre>
    <h2 {...{
      "id": "example-"
    }}>{`Example 🤓`}</h2>
    <p>{`By default, the dialog renders centered on top of the content and with a grayed out background that if clicked will close the dialog.`}</p>
    <Playground __position={1} __code={'<DialogsExample title=\"Dialog title\" dialogId=\"first-dialog-example\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiDialog,
      UiDialogType,
      DialogsExample,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <DialogsExample title="Dialog title" dialogId="first-dialog-example" mdxType="DialogsExample" />
    </Playground>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/dialog/docs/utils/DialogsExample.tsx#L15"
      }}>{`DialogsExample`}</a>{` that we use throughout this doc. This is an example of the code of a component using a dialog:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React from 'react';

import { UiButton } from '@uireact/button';
import { UiText } from '@uireact/text';
import { UiDialog, UiDialogType, useDialog } from '@uireact/dialog';

export const DialogsExample: React.FC<DialogsExampleProps> = () => {
  const dialogId = 'someFancyDialogId';
  const { isOpen, actions } = useDialog(dialogId); //Instantiating the dialog hook with a dialogId : string

  React.useEffect(() => {
    if (isOpen) {
      // Do something fancy if the dialog is opened, maybe tracking analytics?
    }
  }, [isOpen]);

  const onClickCB = React.useCallback(() => {
    actions.openDialog(); // This open dialog function internally triggers the open dialog fn in the dialog controller and it passes the dialog id that was used in the useDialog hook.
  }, [actions]);

  return (
    <>
      <UiButton onClick={onClickCB}>Open dialog</UiButton>
      <UiDialog dialogId={dialogId}>
        <UiText>Dialog content</UiText>
      </UiDialog>
    </>
  );
};
`}</code></pre>
    <p>{`Under the hook the `}<inlineCode parentName="p">{`UiDialog`}</inlineCode>{` intantiates the `}<inlineCode parentName="p">{`useDialog`}</inlineCode>{` hook to know if is opened or closed. You can see this `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/dialog/src/ui-dialog.tsx#L32"
      }}>{`here`}</a></p>
    <p>{`The magic of using the `}<inlineCode parentName="p">{`useDialog`}</inlineCode>{` hook is that you can control this dialog from anywhere in the react tree you just need to use the same dialog and the `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` or `}<inlineCode parentName="p">{`actions`}</inlineCode>{` param will be linked to given dialog.`}</p>
    <h2 {...{
      "id": "props-️"
    }}>{`Props 🛠️`}</h2>
    <Props of={UiDialog} mdxType="Props" />
    <br />
    <h2 {...{
      "id": "more-dialog-examples-"
    }}>{`More dialog examples 🤓`}</h2>
    <br />
    <h3 {...{
      "id": "fullscreen-dialog"
    }}>{`Fullscreen dialog`}</h3>
    <p>{`Renders at the right of the screen`}</p>
    <Playground __position={5} __code={'<DialogsExample\n  type={UiDialogType.FULLSCREEN}\n  dialogId=\"fullscreen-example\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiDialog,
      UiDialogType,
      DialogsExample,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <DialogsExample type={UiDialogType.FULLSCREEN} dialogId="fullscreen-example" mdxType="DialogsExample" />
    </Playground>
    <h3 {...{
      "id": "bottom-dialog"
    }}>{`Bottom dialog`}</h3>
    <p>{`Renders at the bottom of the screen`}</p>
    <Playground __position={6} __code={'<DialogsExample type={UiDialogType.BOTTOM} dialogId=\"bottom-example\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiDialog,
      UiDialogType,
      DialogsExample,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <DialogsExample type={UiDialogType.BOTTOM} dialogId="bottom-example" mdxType="DialogsExample" />
    </Playground>
    <h3 {...{
      "id": "left-dialog"
    }}>{`Left dialog`}</h3>
    <p>{`Renders at the left of the screen`}</p>
    <Playground __position={7} __code={'<DialogsExample type={UiDialogType.LEFT} dialogId=\"left-example\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiDialog,
      UiDialogType,
      DialogsExample,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <DialogsExample type={UiDialogType.LEFT} dialogId="left-example" mdxType="DialogsExample" />
    </Playground>
    <h3 {...{
      "id": "right-dialog"
    }}>{`Right dialog`}</h3>
    <p>{`Renders at the right of the screen`}</p>
    <Playground __position={8} __code={'<DialogsExample type={UiDialogType.RIGHT} dialogId=\"right-example\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiDialog,
      UiDialogType,
      DialogsExample,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <DialogsExample type={UiDialogType.RIGHT} dialogId="right-example" mdxType="DialogsExample" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      